import { dev } from '$app/environment'
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

if (!dev) {
	Sentry.init({
		dsn: 'https://e1e8b8cc980e26c0d49204e04e8055b2@o4505997826129920.ingest.sentry.io/4505997827768320',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 1.0, // 0.1

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [new Replay()],
	})

	// // If you have a custom error handler, pass it to `handleErrorWithSentry`
	// export const handleError = handleErrorWithSentry()
}
