import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/admin/einstellungen": [~5,[2]],
		"/(app)/admin/team": [~6,[2]],
		"/login": [~9],
		"/(app)/projekt/[huid]": [~7,[2],[,3]],
		"/sentry-example": [10],
		"/(app)/shortener": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';